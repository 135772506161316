import { MenuProps } from "../components/Menu/MenuTypes"

/*const prod = {
  API_URL: "https://api.bookolicos.com/",
  /*STRIPE_API_KEY: "pk_live_51MlhrWC82AMUaff6Op7HykM2k4RDWBrGU0RoxFeiq9ev7jmxY69hNq2U3tq2cM6K4KnSObO94MAWgSzXNy6iq2tm00RCVG66Mc"*/
  /*STRIPE_API_KEY: "pk_test_51MlhrWC82AMUaff6ETLL7nkOhpKzkEHszMB0v2XcUG3n7mINkXreAOMjrjOoYoIJl7jhqpWRMfkgwabQUimZnsyS00CpGDrKM6",
  PAGE_SIZE: 15,
  TOKEN_SECRET: "w€B_T0k3N_5EcR3t"
};*/

const prod = {
  API_URL: "https://api-dev.bookolicos.com/",
  STRIPE_API_KEY: "pk_test_51MlhrWC82AMUaff6ETLL7nkOhpKzkEHszMB0v2XcUG3n7mINkXreAOMjrjOoYoIJl7jhqpWRMfkgwabQUimZnsyS00CpGDrKM6",
  PAGE_SIZE: 25,
  TOKEN_SECRET: "w€B_T0k3N_5EcR3t"
};

const dev = {
  API_URL: "http://localhost:4000/",
  STRIPE_API_KEY: "pk_test_51MlhrWC82AMUaff6ETLL7nkOhpKzkEHszMB0v2XcUG3n7mINkXreAOMjrjOoYoIJl7jhqpWRMfkgwabQUimZnsyS00CpGDrKM6",
  PAGE_SIZE: 25,
  TOKEN_SECRET: "w€B_T0k3N_5EcR3t"
};

export const types = process.env.NODE_ENV === "development" ? dev : prod;

export const defaultMenuProps: MenuProps = {
  items : [
    {
      title : 'Clientes',
      link : '/clientes',
      active : false
    },
    {
      title : 'Libros',
      link : '/libros',
      active : false
    },
    {
      title : 'Ventas',
      link : '/ventas',
      active : false
    },
    {
      title : 'Cursos',
      link : '/cursos',
      active : false
    },   
    {
      title : 'Ventas cursos',
      link : '/ventas-cursos',
      active : false
    },
    {
      title : 'Noticias',
      link : 'http://blog.bookolicos.com/wp-admin/edit.php?wtlwp_token=72cec43ceb564e3bfe0e1080ce3fad3866994f55efc307d130e08ca0655fbc08055c29fbd86bddc3d9ea561a976086c5e0a5db1a5b1fc5658dcea89109682061',
      active : false
    }
  ]
}